/* eslint-disable no-debugger */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import InternationalMain from "./InternationalLayouts/Main";
import CNMain from "./CNLayouts/Main";
import GlobalConfig from "@src/GlobalConfig";
import { LanguageContext } from "@src/Basic/Language/Provider";
import i18n from "@src/Basic/Language/i18n";
import _ from "lodash";
import { useStore } from "@src/Store";

const Framework = () => {
  const navigator = useNavigate();
  const { setLanguage, language } = useContext(LanguageContext);
  const { pathname, state } = useLocation();
  const { commonStore } = useStore();

  const [isInternational, setIsInternational] = useState<boolean>(GlobalConfig.isInternational);
  const isDev = process.env.NODE_ENV === "development";
  const { lang } = useParams();
  const i18Language = useMemo(() => i18n.language, [i18n.language]);

  // 开发环境，根据 localStorage 设置国际化
  useEffect(() => {
    if (!isDev) return;
    setIsInternational(localStorage.getItem("isInternational") === "1");
  }, [isDev]);

  const isIncompletePath = (path: string) => {
    // 过滤掉空字符串
    const parts = path.split("/").filter(Boolean);
    // 国家 语言 路径
    if (parts.length < 2) {
      return true;
    }
    return false;
  };

  const extractSpecificPath = (path: string) => {
    const match = path.match(
      /\/(en_au|en_ca|de_de|de_de|it_it|en_sg|en_gb|en_us|zh_cn|zh_tw|es_es|en_de|zh_CN|es|en_US|es_ES)\/(.*)/
    );
    console.log("match[1]", match?.[1], match?.[2]);
    if (match && match[2]) {
      return `${match[2]}`;
    }
    return "";
  };

  useEffect(() => {
    if (isIncompletePath(pathname)) {
      // path路径不完整
      navigator(`/${language}/home`);
    } else if (lang) {
      // 切换语言
      const path = extractSpecificPath(pathname);

      console.log("path", extractSpecificPath(pathname));

      const stateParams = Object.keys(state || {})?.length ? state : {};
      // debugger;
      if (["es-legal-notice", "es-cookie", "es-private"].includes(path)) {
        const envPath = language === "es_es" ? path : path.replace("es", "en");
        navigator(`/${language}/${envPath}`, { state: stateParams });
      } else if (["en-legal-notice", "en-cookie", "en-private"].includes(path)) {
        const envPath = language === "es_es" ? path.replace("en", "es") : path;
        navigator(`/${language}/${envPath}`, { state: stateParams });
      } else {
        navigator(`/${language}/${path}`, { state: stateParams });
      }
    }
  }, [language, pathname]);

  useEffect(() => {
    if (language) {
      setLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    console.log("isInternational===>", language, isInternational, i18n.getSupportedLngs());
    if (isInternational) {
      setLanguage(_.includes(i18n.getSupportedLngs(), language) ? language : "es_es"); // 国际版默认es
    } else {
      setLanguage(_.includes(i18n.getSupportedLngs(), language) ? language : "zh_cn"); // 国内版默认简体中文
    }
  }, [isInternational]);

  return (
    <div className="w-full h-full">
      {/* <div className="flex fixed top-0 z-[999999]">
        <button
          onClick={() => {
            isInternational ? GlobalConfig.pushToCN() : GlobalConfig.pushToInternational();
          }}
        >
          switch env
        </button>
      </div> */}

      {isInternational ? <InternationalMain /> : <CNMain />}
    </div>
  );
};
export default Framework;
