import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ParaTuClientes } from "@src/assets/international/svgs/paraTuClientes.svg";
import { ReactComponent as Adminstraction } from "@src/assets/international/svgs/adminstraction.svg";
import { ReactComponent as GestionTodoEnUno } from "@src/assets/international/svgs/gestionTodoEnUno.svg";

export interface ISubContent {
  title?: string;
  titleIcon?: ReactNode;
  key?: string;
  path?: string;
  list?: INav[];
}

export interface INav {
  title?: string;
  titleIcon?: ReactNode;
  key?: string;
  path?: string;
  chevron?: string;
  content?: ISubContent[];
  showDisabled?: boolean; // 没有的菜单置灰 但是要展示出来
}

const useNavList = () => {
  const { t, i18n } = useTranslation();

  const NavContent: INav[] = useMemo(() => {
    return [
      {
        title: t(InternationalEnum.products, { ns: "InternationalWebsite" }),
        key: "productos",
        path: "/home",
        chevron: "down",
        content: [
          {
            title: t(InternationalEnum.forYourClients, { ns: "InternationalWebsite" }),
            titleIcon: <ParaTuClientes />,
            key: "para-tu-clientes",
            list: [
              {
                title: t(InternationalEnum.QROrder, { ns: "InternationalWebsite" }),
                key: "qr-order",
                path: "/qr-order",
              },
              {
                title: t(InternationalEnum.reserves, { ns: "InternationalWebsite" }),
                key: "reservas",
                path: "/reservas",
              },
              {
                title: t(InternationalEnum.digitalMenu, { ns: "InternationalWebsite" }),
                key: "carta-digital",
                path: "/carta-digital",
              },
              {
                title: t(InternationalEnum.quioscos, { ns: "InternationalWebsite" }),
                key: "quioscos",
                path: "/quioscos",
              },
            ],
          },
          {
            title: t(InternationalEnum.integration, { ns: "InternationalWebsite" }),
            titleIcon: <GestionTodoEnUno />,
            key: "gestion-todo-enUno",
            list: [
              {
                title: t(InternationalEnum.backoffice, { ns: "InternationalWebsite" }),
                key: "back-office",
                path: "/back-office",
              },
              {
                title: t(InternationalEnum.TPVHotel, { ns: "InternationalWebsite" }),
                key: "tpv",
                path: "/tpv",
              },
              {
                title: t(InternationalEnum.KDS, { ns: "InternationalWebsite" }),
                key: "kds",
                path: "/kds",
              },
              {
                title: t(InternationalEnum.electricMenu, { ns: "InternationalWebsite" }),
                key: "electronic-menu",
                path: "/electronic-menu",
              },
              {
                title: t(InternationalEnum.RSinjightApplication, { ns: "InternationalWebsite" }),
                key: "rs-insight-app",
                path: "/rs-insight-app",
              },
              {
                title: t(InternationalEnum.deliveryAndTakeout, { ns: "InternationalWebsite" }),
                key: "delivery-takeout",
                path: "/delivery-takeout",
              },
            ],
          },
          {
            title: t(InternationalEnum.administrate, { ns: "InternationalWebsite" }),
            titleIcon: <Adminstraction />,
            key: "adminstraction",
            list: [
              {
                title: t(InternationalEnum.gestionyControl, { ns: "InternationalWebsite" }),
                key: "gestiony-control",
                path: "/gestiony-control",
              },
              {
                title: t(InternationalEnum.member, { ns: "InternationalWebsite" }),
                key: "member",
                path: "/member",
              },
              {
                title: t(InternationalEnum.marketing, { ns: "InternationalWebsite" }),
                key: "marketing",
                path: "/marketing",
              },
            ],
          },
        ],
      },
      {
        title: t(InternationalEnum.restoPara, { ns: "InternationalWebsite" }),
        key: "restoPara",
        path: "/resto-para",
      },
      {
        title: t(InternationalEnum.descargar, { ns: "InternationalWebsite" }),
        key: "download-center",
        path: "/download-center",
      },
      {
        title: t(InternationalEnum.recursos, { ns: "InternationalWebsite" }),
        key: "recursos",
        chevron: "down",
        content: [
          {
            key: Symbol().toString(),
            list: [
              {
                title: t(InternationalEnum.about, { ns: "InternationalWebsite" }),
                key: "sobreNosotros",
                path: "/sobre-nosotros",
              },
            ],
          },
          // {
          //   key: Symbol().toString(),
          //   list: [
          //     {
          //       title: t(InternationalEnum.blog, { ns: "InternationalWebsite" }),
          //       key: "/blog",
          //       path: "/home",
          //     },
          //     {
          //       title: t(InternationalEnum.centro, { ns: "InternationalWebsite" }),
          //       key: "/centro",
          //       path: "/home",
          //     },
          //   ],
          // },
        ],
      },
      // {
      //   title: t(InternationalEnum.efficiency, { ns: "InternationalWebsite" }),
      //   key: "tarifas",
      //   path: "/tarifas",
      // },
      // {
      //   title: t(InternationalEnum.contact, { ns: "InternationalWebsite" }),
      //   key: "contact",
      //   path: "/contact",
      // },
      // {
      //   title: t(InternationalEnum.about, { ns: "InternationalWebsite" }),
      //   key: "sobreNosotros",
      //   path: "/sobre-nosotros",
      // },
    ];
  }, [i18n.language]);

  return {
    navList: NavContent,
  };
};

export default useNavList;
