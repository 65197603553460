import React from "react";
import styles from "./index.module.less";
import { useNavigate, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import { IKVProps } from "@src/Model/IKVProps";

export interface IButtonProps extends React.HTMLProps<HTMLDivElement> {
  btnText?: string;
  btnLinkTo?: string;
  isLinkBtn?: boolean;
  btnShowArrowRight?: boolean;
  customHoverBtnStyle?: IKVProps;
  /**
   *  customHoverBtnStyle={{
        "--hover-after-background": "#4340f4",
        "--hover-after-color": "#ffffff",
        "--hover-before-color": "#4340f4",
        "--hover-before-background": "#ffffff",
        // "--hover-svg-color": "red",
     }}
   */
}

const arrow = `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7502 11.0002L14.2199 8.53051C14.5129 8.23761 14.5129 7.76272 14.2199 7.46982L11.7502 5.00009M14.0003 8.00017L3 8.00017" stroke="#4340F3" stroke-width="1.1" stroke-linecap="round"/>
</svg>
`;

const linkArrow = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 16L19.2929 12.7071C19.6834 12.3166 19.6834 11.6834 19.2929 11.2929L16 8M19 12L5 12" stroke="#4340F4" stroke-width="1.5" stroke-linecap="round"/>
</svg>
`;

const HoverButton = (props: IButtonProps) => {
  const { btnText, btnLinkTo, style, isLinkBtn, btnShowArrowRight, customHoverBtnStyle } = props;

  const navigator = useNavigate();
  const { lang } = useParams();

  const handleButtonClick = () => {
    navigator(`/${lang}${btnLinkTo}`);
  };

  const renderContent = (type: string) => {
    return btnShowArrowRight ? (
      <label className="flex items-center justify-center gap-1">
        <span>{btnText}</span>
        <span className={`${styles.svgIcon}`}>
          {type === "button" ? <SVG src={arrow} /> : <SVG src={linkArrow} />}
        </span>
      </label>
    ) : (
      <label>{btnText}</label>
    );
  };

  return isLinkBtn ? (
    <div className={`${styles.PCLinkButton}`} onClick={handleButtonClick}>
      {renderContent("link")}
    </div>
  ) : (
    <div
      className={`${styles.PCButton}`}
      onClick={handleButtonClick}
      style={{
        ...style,
        ...customHoverBtnStyle,
      }}
      key={"hoverbutton"}
    >
      <div className={`${styles.PCContent}`}>{renderContent("button")}</div>
    </div>
  );
};

export default HoverButton;
