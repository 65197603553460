import { IKVProps } from "@src/Model/IKVProps"
import AxiosRequest from "@src/Utils/axios"

export function addUserContactCN(params: IKVProps = {}) {
    return AxiosRequest.post('/websiteApi/operation-manager/userContact/addUserContact', params)
}
export function queryHeadArticle(params: IKVProps = {}) {
    return AxiosRequest.get('/websiteApi/operation-manager/article/queryHeadArticle', params)
}
export function queryArticleContent(params: IKVProps = {}) {
    return AxiosRequest.post('/websiteApi/operation-manager/article/queryArticleContent', params)
}
export function searchArticleById(params: IKVProps, config?: any) {
    return AxiosRequest.get("/websiteApi/operation-manager/article/searchArticleById", params, config);
}

