import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./index.module.less";
import InternationalEnum from "@src/Locales/Constants/InternationalEnum";
import HoverButton from "@src/Components/InternationalComponents/HoverButton";
import useImagesLanguage, { IImagesProps } from "@src/Hooks/International/hooks/useImageLanuage";
import { renderImageItem } from "@src/Components/InternationalComponents/utils";
import i18n from "@src/Basic/Language/i18n";

interface IMenu {
  title: string;
  key: string;
  path: string;
}

interface ILinkIcon {
  icon: IImagesProps;
  key: string;
  path: string;
}

const MobileFooter = () => {
  const { t } = useTranslation();
  const { home } = useImagesLanguage();
  const { lang } = useParams();
  const navigator = useNavigate();

  const linkIcon: ILinkIcon[] = [
    { icon: home[34], key: "1", path: "https://www.instagram.com/restosuite/" },
    { icon: home[35], key: "2", path: "none" },
    { icon: home[36], key: "3", path: "none" },
    { icon: home[37], key: "4", path: "none" },
  ];

  const menuContent: IMenu[] = [
    {
      title: t(InternationalEnum.QROrder, { ns: "InternationalWebsite" }),
      key: "qr-order",
      path: "/qr-order",
    },
    {
      title: t(InternationalEnum.TPVHotel, { ns: "InternationalWebsite" }),
      key: "tpv",
      path: "/tpv",
    },
    {
      title: t(InternationalEnum.reserves, { ns: "InternationalWebsite" }),
      key: "reservas",
      path: "/reservas",
    },
    {
      title: t(InternationalEnum.RSinjightApplication, { ns: "InternationalWebsite" }),
      key: "rs-insight-app",
      path: "/rs-insight-app",
    },
    {
      title: t(InternationalEnum.digitalMenu, { ns: "InternationalWebsite" }),
      key: "carta-digital",
      path: "/carta-digital",
    },
    {
      title: t(InternationalEnum.deliveryAndTakeout, { ns: "InternationalWebsite" }),
      key: "delivery-takeout",
      path: "/delivery-takeout",
    },
    {
      title: t(InternationalEnum.quioscos, { ns: "InternationalWebsite" }),
      key: "quioscos",
      path: "/quioscos",
    },
    {
      title: t(InternationalEnum.member, { ns: "InternationalWebsite" }),
      key: "member",
      path: "/member",
    },
    {
      title: t(InternationalEnum.KDS, { ns: "InternationalWebsite" }),
      key: "kds",
      path: "/kds",
    },
    {
      title: t(InternationalEnum.marketing, { ns: "InternationalWebsite" }),
      key: "marketing",
      path: "/marketing",
    },
    {
      title: t(InternationalEnum.backoffice, { ns: "InternationalWebsite" }),
      key: "back-office",
      path: "/back-office",
    },
    {
      title: t(InternationalEnum.electricMenu, { ns: "InternationalWebsite" }),
      key: "electronic-menu",
      path: "/electronic-menu",
    },
  ];

  const resourceContent: IMenu[] = [
    {
      title: t(InternationalEnum.about, { ns: "InternationalWebsite" }),
      key: "sobre-nosotros",
      path: "/sobre-nosotros",
    },
    {
      title: t(InternationalEnum.blog, { ns: "InternationalWebsite" }),
      key: "bloge",
      path: "/home",
    },
    {
      title: t(InternationalEnum.descargar, { ns: "InternationalWebsite" }),
      key: "/download-center",
      path: "/download-center",
    },
  ];

  const legalContent: IMenu[] = [
    {
      title: t(InternationalEnum.footerPrivacyPolicy, { ns: "InternationalWebsite" }),
      key: "es-private",
      path: "/es-private",
    },
    {
      title: t(InternationalEnum.footerlegalNotice, { ns: "InternationalWebsite" }),
      key: "es-legal-notice",
      path: "/es-legal-notice",
    },
    {
      title: t(InternationalEnum.cookiePolicy, { ns: "InternationalWebsite" }),
      key: "es-cookie",
      path: "/es-cookie",
    },
  ];

  const handleIconLink = (o: ILinkIcon) => {
    if (o.path !== "none") {
      window.open(o.path, "_blank");
    }
  };

  const handleMenuClick = (o: IMenu) => {
    const env = i18n.language;
    if (o.path && ["/es-legal-notice", "/es-cookie", "/es-private"].includes(o?.path)) {
      const envPath = env === "es_es" ? o.path : o.path.replace("es", "en");
      navigator(`/${lang}${envPath}`);
    } else {
      navigator(`/${lang}${o.path}`);
    }
  };

  const renderColumn1ForCompany = () => {
    return (
      <div className={`${styles.PCColumn1ForCompany}`}>
        <div className={`${styles.PCFooterLogo}`}>{renderImageItem(home[33])}</div>
        {/* <div className={`${styles.PCIconLink}`}>
          {linkIcon?.map((o) => {
            return (
              <div className="PCLinkItem" onClick={() => handleIconLink(o)}>
                {renderImageItem(o.icon)}
              </div>
            );
          })}
        </div> */}
        <div className="flex ">
          <HoverButton
            btnText={t(InternationalEnum.requestYourPresentationButton, {
              ns: "InternationalWebsite",
            })}
            btnLinkTo="/contact"
          />
        </div>
      </div>
    );
  };

  const renderColumn2ForMenu = () => {
    return (
      <div className={`${styles.PCColumn2ForMenu}`}>
        <h1>{t(InternationalEnum.products, { ns: "InternationalWebsite" })}</h1>
        <div className={`${styles.PCMenuGrid}`}>
          {menuContent?.map((o) => (
            <label onClick={() => handleMenuClick(o)}>{o.title}</label>
          ))}
        </div>
      </div>
    );
  };

  const renderColumn3ForAbout = () => {
    return (
      <div className={`${styles.PCColumn2ForResource}`}>
        <h1>{t(InternationalEnum.recursos, { ns: "InternationalWebsite" })}</h1>
        <div className={`${styles.PCMenu}`}>
          {resourceContent?.map((o) => (
            <label onClick={() => handleMenuClick(o)}>{o.title}</label>
          ))}
        </div>
      </div>
    );
  };

  const renderColumn4ForLegal = () => {
    return (
      <div className={`${styles.PCColumn4ForLegal}`}>
        <h1>{t(InternationalEnum.footerColumnsTitle, { ns: "InternationalWebsite" })}</h1>
        <div className={`${styles.PCMenu}`}>
          {legalContent?.map((o) => (
            <label onClick={() => handleMenuClick(o)}>{o.title}</label>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.PCFooterWrap}`}>
        <div className={`flex flex-col ${styles.PCFooterContent}`}>
          {renderColumn1ForCompany()}
          {renderColumn2ForMenu()}
          {renderColumn3ForAbout()}
          {renderColumn4ForLegal()}
          <div className={`${styles.PCCopyRight}`}>
            <p>{t(InternationalEnum.footerColumn1CopyRight, { ns: "InternationalWebsite" })},</p>
            <p>
              {t(InternationalEnum.footerColumn1AllRightsReserved, { ns: "InternationalWebsite" })},
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileFooter;
