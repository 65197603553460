import { IKVProps } from "@src/Model/IKVProps"
import localeResources from "../Locales/localResources.json";
import { rootStore } from "@src/Store";
import _ from "lodash";

// 在指定模块中获取指定语言的资源
export const getTargetModelLanguageResource = (
    bizModelData: IKVProps[],
    languageKey: string
) => {
    const fallbackLng = rootStore.commonStore.isInternational ? "en_us" : "zh_cn";
    const fallbackResource: any = (localeResources as any)?.[fallbackLng];
    return bizModelData.reduce((result: any, item: any) => {
        // 这里先取远程本语,再取远程保底语言,再取本地保底语言
        if (item?.languageText) {
            if (languageKey in item.languageText) {
                result[item.key] = item.languageText[languageKey];
            } else if (fallbackLng in item.languageText) {
                result[item.key] = item.languageText[fallbackLng];
            }
        } else {
            result[item.key] = fallbackResource?.[item.domain]?.[item.key] || "";
        }

        return result;
    }, {});
};

// 国外版官网用
export const customLanguageSort = (languages: IKVProps[]): IKVProps[] => {
    // 按照语言label进行排序
    const sortedLanguages = _.sortBy(languages, 'label');
    return sortedLanguages
}

export const resetViewport = (content: string) => {
    let metaViewport: any = document.querySelector("meta[name='viewport']");

    if (metaViewport) {
        metaViewport.parentNode?.removeChild(metaViewport);
    }


    metaViewport = document.createElement("meta");
    metaViewport.name = "viewport";
    metaViewport.content = content;

    document.head.appendChild(metaViewport);
};

